import { MarketingEvent } from '@/types'
import { cls, formatTimeRange } from '@/utils'
import Button from '@/components/button'
import IconArrowTopRight from '@haiper/icons-svg/icons/outline/arrow-top-right.svg'
import { HTMLAttributes, useCallback, useMemo } from 'react'
import ShadowImageCard, { ShadowImageCardProps } from '@/components/shadow-image-card'
import { useRouter } from 'next/navigation'
import useAmplitude from '@/hooks/useAmplitude'
import { useSetAtom } from 'jotai'
import { readedNewFeatureKeysAtom } from '@/atoms'
import { templateDialogVisibleAtom } from '../new-feature-dialog/template-of-the-day'
import useTemplateOfTheDay from '@/hooks/useTemplateOfTheDay'

export interface EventCardProps
  extends HTMLAttributes<HTMLDivElement>,
    Pick<ShadowImageCardProps, 'variant' | 'className'> {
  data: MarketingEvent
}

/**
 * EventCard component
 * Event card is a card that displays an event, it usually contains an image, title, and description.
 * Also, it can contain a button to redirect to the event page.
 * Besides, it can contain a mask gradient background image on the left to make the text more readable.
 */
export default function EventCard({ className, data, variant = 'default', onClick, ...props }: EventCardProps) {
  const timeRangeStr = useMemo(() => {
    return formatTimeRange(data.start_time, data.end_time)
  }, [data])

  const action = data?.action ?? null
  const router = useRouter()

  const { track } = useAmplitude()
  const setReadedNewFeatureKeys = useSetAtom(readedNewFeatureKeysAtom)
  const setTemplateDialogVisiblb = useSetAtom(templateDialogVisibleAtom)

  const { data: templateOfTheDay, event: templateOfTheDayEvent } = useTemplateOfTheDay()

  const openCampignDialog = useCallback(() => {
    track('click:campign:open-modal')
    setReadedNewFeatureKeys((old) => old.filter((e) => e !== templateOfTheDayEvent?.id))
    setTemplateDialogVisiblb(true)
  }, [setReadedNewFeatureKeys, track, setTemplateDialogVisiblb, templateOfTheDayEvent])

  const handleClick = useCallback(
    async (e: any) => {
      track('click:event:card', { event_id: data.id })
      if (onClick) {
        onClick?.(e)
        return
      }

      if (action?.type === 'open_url') {
        router.push(action.payload?.url)
      } else if (action?.type === 'open_event') {
        router.push(`/event?evt=${data.id}`)
      } else if (action?.type === 'popup_templates') {
        openCampignDialog()
      } else if (action?.type === 'custom') {
        await action?.payload?.onClick?.()
      }
    },
    [action, router, openCampignDialog, data, onClick, track],
  )

  return (
    <div
      {...props}
      className={cls('relative z-0 rounded-lg overflow-hidden border cursor-pointer', className)}
      onClick={handleClick}
    >
      <ShadowImageCard variant={variant} src={data.image} alt='shadow image card' maskClassName={data?.mask_style} />
      <div
        className='z-10 text-text-on-color absolute inset-0 p-4 flex flex-col justify-between'
        aria-label='foreground'
      >
        <div className='w-full flex flex-col gap-2'>
          <span className='text-heading-4xl font-bold leading-[100%]'>{data.title}</span>
          <span className='text-body-md leading-6 tracking-15'>{data.abstract}</span>
          {timeRangeStr && <span className='text-body-md leading-6 tracking-15 font-extralight'>{timeRangeStr}</span>}
        </div>
        <Button variant='outline' className='h-7 w-max rounded-full px-3 py-1 gap-0 text-text'>
          <div className='flex items-center'>
            <span className='px-1'>{action.name}</span>
            <IconArrowTopRight className='text-icon size-5' />
          </div>
        </Button>
      </div>
    </div>
  )
}
