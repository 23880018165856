import EventCard from '@/components/event-card'
import useBrowser from '@/hooks/useBrowser'
import useMarketingEvents from '@/hooks/useMarketingEvents'
import { MarketingEvent } from '@/types'
import { cls } from '@/utils'
import { useRouter } from 'next/navigation'
import { useCallback } from 'react'

export interface HomeEventsProps {
  className?: string
}

export default function HomeEvents({ className }: HomeEventsProps) {
  const browser = useBrowser()

  const { data: marketingEvents } = useMarketingEvents()
  const router = useRouter()

  const handleEventClick = useCallback(
    (event: MarketingEvent) => {
      // if (event.action.type === 'open_url' && event.action.payload?.url) {
      //   openNewTab(event.action.payload.url)
      // }
      router.push(`/event?evt=${event.id}`)
    },
    [router],
  )

  return (
    <div className={cls('flex flex-col gap-4 w-full h-0 flex-1', browser?.name === 'safari' && 'md:grow-0', className)}>
      {marketingEvents?.map((event, index) => {
        if (event.id === 'template_of_the_day') {
          return null
        }
        return (
          <EventCard
            key={[event.title, index].join()}
            className='z-0 relative w-full h-[169px] min-h-[169px] flex-1 overflow-hidden rounded-lg'
            aria-label='marketing event'
            data={event}
            onClick={() => handleEventClick(event)}
          />
        )
      })}
    </div>
  )
}
