import Link from '@/components/link'
import { cls } from '@/utils'
import { PropsWithChildren } from 'react'
import IconChevronRight from '@haiper/icons-svg/icons/outline/chevron-right.svg'
import Button from '@/components/button'

export interface HomeContainerProps {
  className?: string
  title: string
  moreLink?: string
}

export default function HomeContainer({ children, title, moreLink, className }: PropsWithChildren<HomeContainerProps>) {
  return (
    <div
      className={cls('flex flex-col gap-4 p-[22px] rounded-[32px] bg-surface-subdued', className)}
      aria-label='home container'
    >
      <div className='flex items-center justify-between h-7' aria-label='home container title'>
        <span className='text-heading-2xl font-bold tracking-45 leading-6'>{title}</span>
        {moreLink && (
          <Button variant='primary' className='h-7 rounded-full px-3 py-1'>
            <Link href={moreLink} className='text-body-lg font-medium tracking-32 flex items-center leading-6'>
              <span className='px-1'>More</span>
              <IconChevronRight className='size-5 text-icon-on-color' />
            </Link>
          </Button>
        )}
      </div>
      {children}
    </div>
  )
}
