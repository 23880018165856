'use client'

import CreationInput from '@/components/creation-input'
import Link from '@/components/link'
import { Creation, CreationBase, CreationMode, CreationOutput, MarketingEvent, VideoTag } from '@/types'
import Gallery, { GalleryFilter } from '@/components/gallery'
import TheEnd from '@/components/the-end'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useRouter } from 'next/navigation'
import useAmplitude from '@/hooks/useAmplitude'
import { useAtom, useSetAtom } from 'jotai'
import {
  activeTemplateIdAtom,
  creationInputAtom,
  outputCacheAtom,
  readedNewFeatureKeysAtom,
  subscriptionDialogContentAtom,
} from '@/atoms'
import { getCreationDetailUrl, cls, stopPropagation, whisper } from '@/utils'
import { useInfiniteTopCreations } from '@/hooks/useTopCreations'
import IconChevronRight from '@haiper/icons-svg/icons/outline/chevron-right.svg'
import useAutoCleanCreationCache from '@/hooks/useAutoCleanCreationCache'
import useConfig from '@/hooks/useConfig'
import { useInfiniteTagCreations } from '@/hooks/useTagCreations'
import { useCachedSwitches } from '@/hooks/useSwitches'
import CreditUpgradeButton from '@/components/credit-upgrade-button'
import { NEW_FEATURE_MODEL_2_0, NO_OUTLINE_STYLE, creationInputTranslateStyle } from '@/constants'
import LogoSymbol from '@/public/assets/logo-symbol.svg'
import BadgeNew from '@/components/badges/new'
import useActivePlan from '@/hooks/useActivePlan'
import BadgeUpdate from '@/components/badges/update'
import useCreationModes from '@/hooks/useCreationModes'
import SocialLinks from '@/components/social-links'
import GlobalContainer from '@/components/global-container'
import useAutoCleanOutputCache from '@/hooks/useAutoCleanOutputCache'
import { templateDialogVisibleAtom } from '@/components/new-feature-dialog/template-of-the-day'
import TemplatesExploreCompact from '@/components/templates/explore/compact'
import HomeContainer from './container'
import HomeEvents from './events'
import { model2DialogVisibleAtom } from '@/components/new-feature-dialog/model-2'
import Scrollable from '@/components/scrollable'
import EventCard from '@/components/event-card'
import useBrowser from '@/hooks/useBrowser'

const specialTags = ['top', 'latest']
const disabledTags = ['latest']

const showSpotlightsInsideCreations = true
// const showSpotlightsInsideCreations = false
// const showTemplates = true

const showModel2Event = false

export default function Home() {
  const {
    data: topCreations,
    isValidating: topCreationsLoading,
    hasMore: hasMoreTopCreations,
    loadMore: loadMoreTopCreations,
  } = useInfiniteTopCreations()
  const { data: creationModeOptions } = useCreationModes()

  const [tag, setTag] = useState<string>('')

  const {
    data: tagCreations,
    isValidating: tagCreationsLoading,
    hasMore: hasMoreTagCreations,
    loadMore: loadMoreTagCreations,
  } = useInfiniteTagCreations({ tag })

  const { creations, creationsLoading, hasMore, loadMore } = useMemo(() => {
    const result = tag
      ? {
          creations: tagCreations,
          creationsLoading: tagCreationsLoading,
          hasMore: hasMoreTagCreations,
          loadMore: loadMoreTagCreations,
        }
      : {
          creations: topCreations,
          creationsLoading: topCreationsLoading,
          hasMore: hasMoreTopCreations,
          loadMore: loadMoreTopCreations,
        }

    // filter out private creations (if any)
    result.creations = {
      ...(result.creations as any),
      records: result.creations?.records.filter((e) => e.is_public !== false) ?? [],
    }
    return result
  }, [
    tag,
    topCreations,
    topCreationsLoading,
    tagCreations,
    tagCreationsLoading,
    hasMoreTopCreations,
    hasMoreTagCreations,
    loadMoreTopCreations,
    loadMoreTagCreations,
  ])

  const { data: switches, isValidating: switchesLoading } = useCachedSwitches()
  const setOutputCache = useSetAtom(outputCacheAtom)

  const showTags = switches?.tags ?? false

  const { data: videoTags } = useConfig<VideoTag[]>(showTags ? 'video-tags' : '')

  const fixedTags: VideoTag[] = useMemo(() => {
    return videoTags?.filter((e) => e.fixed && !disabledTags.includes(e.value)) ?? []
  }, [videoTags])

  const handleFilterGallery = useCallback(
    (filter: GalleryFilter) => {
      setTag(fixedTags.some((e) => e.value === filter.value) && !specialTags.includes(filter.value) ? filter.value : '')
    },
    [fixedTags],
  )

  const { track } = useAmplitude()
  const setReadedNewFeatureKeys = useSetAtom(readedNewFeatureKeysAtom)
  const setModel2DialogVisible = useSetAtom(model2DialogVisibleAtom)
  const setTemplateDialogVisiblb = useSetAtom(templateDialogVisibleAtom)

  const openModel2NewFeatureDialog = useCallback(() => {
    track('click:campign:open-modal')
    setReadedNewFeatureKeys((old) => old.filter((e) => e !== NEW_FEATURE_MODEL_2_0))
    setModel2DialogVisible(true)
  }, [setReadedNewFeatureKeys, track, setModel2DialogVisible])

  // clear all cache data
  useAutoCleanCreationCache()
  useAutoCleanOutputCache()

  const router = useRouter()
  const [{ mode }, setCreationInput] = useAtom(creationInputAtom)

  const { data: activePlan, loading: activePlanLoading } = useActivePlan()
  const isFreePlan = (activePlan?.is_free || !activePlan) && !activePlanLoading
  const setSubscriptionDialogContent = useSetAtom(subscriptionDialogContentAtom)

  useEffect(() => {
    track('view:home')
  }, [track])

  const galleryFilters: Array<{ label: string; value: string }> = useMemo(() => {
    return fixedTags.map((tag) => ({
      label: tag.label,
      value: tag.value,
    }))
  }, [fixedTags])

  const handleGotoDetail = useCallback(
    (item: CreationBase) => {
      const detailUrl = getCreationDetailUrl(item)
      track('click:explore:showcase', { creation_id: item.creation_id })
      router.push(detailUrl)
    },
    [router, track],
  )
  const browser = useBrowser()

  const model2Event: MarketingEvent = useMemo(() => {
    return {
      id: 'model-2',
      title: <span className='text-heading-sm font-bold tracking-15'>Introduce our new model</span>,
      // abstract: 'Haiper 2.0',
      abstract: <span className='text-heading-6xl leading-[100%]'>Haiper 2.0</span>,
      content: '',
      image: 'https://scontent.haiper.ai/webapp/images/features/pony.webp',
      start_time: '',
      end_time: '',
      action: {
        name: 'Details',
        type: 'custom',
        payload: {
          onClick: openModel2NewFeatureDialog,
        },
      },
    }
  }, [openModel2NewFeatureDialog])

  useEffect(() => {
    // set output cache
    const records: CreationOutput[] = creations?.records ?? []
    const map = records.reduce(
      (acc, item) => {
        if (item.output_id) {
          acc[item.output_id] = item
        }
        return acc
      },
      {} as Record<string, CreationOutput>,
    )

    setOutputCache((prev) => {
      return {
        // Notice the order, we want to keep the old cache since it may contains like state and more local data
        ...map,
        ...prev,
      }
    })
  }, [creations?.records, setOutputCache])

  const galleryItems: Creation[] = useMemo(() => {
    const records: Creation[] = creations?.records ?? []
    return records.filter((item) => !item.is_illegal && !item.is_nsfw)
  }, [creations?.records])

  const appsScrollViewPortRef = useRef<HTMLDivElement>(null)
  const setActiveTemplateId = useSetAtom(activeTemplateIdAtom)

  const renderCreationModeItem = useCallback(
    (creationMode: CreationMode, index: number) => {
      const { Icon, iconClassName } = creationMode
      const needUpgrade = creationMode.membersOnly && isFreePlan

      return (
        <div
          key={creationMode.mode}
          className={cls(
            'relative cursor-pointer flex items-center bg-surface rounded-[16px] p-3 border-2 border-b-4 border-solid border-border hover:border-border-hover hover:bg-surface-hover text-text flex-1 h-[112px] w-[152px] min-w-[152px] shrink-0',
            mode === creationMode.mode ? 'cursor-default' : '',
            !creationMode.available ? 'hover:bg-white/5 shadow-none cursor-not-allowed hover:border-border' : '',
          )}
          aria-label='creation-mode-item'
          data-testid={`creation-mode-item-${creationMode.mode}`}
          onClick={(e) => {
            if (!creationMode.available) return
            if (needUpgrade) {
              setSubscriptionDialogContent({
                message: `Upgrade to Haiper Membership to access the ${creationMode.name} feature.`,
              })
              return
            }

            setCreationInput((prev) => ({
              ...prev,
              creation: undefined,
              mode: creationMode.mode,
              expanded: true,
              focusing: true,
            }))
            setActiveTemplateId(null)
            // scroll current element's left to appsScrollViewPortRef's left if it is out of appsScrollViewPortRef's left or right
            const viewport = appsScrollViewPortRef.current
            if (viewport) {
              const left = e.currentTarget.offsetLeft
              const right = e.currentTarget.offsetLeft + e.currentTarget.offsetWidth
              const offset = 50
              if (left < viewport.scrollLeft) {
                viewport.scrollBy({
                  left: left - viewport.scrollLeft - offset,
                  behavior: 'smooth',
                })
              } else if (right > viewport.scrollLeft + viewport.clientWidth) {
                viewport.scrollBy({
                  left: right - viewport.scrollLeft - viewport.clientWidth + offset,
                  behavior: 'smooth',
                })
              }
            }
          }}
        >
          <div className={cls('flex gap-2.5 flex-col pt-2.5 w-full')}>
            <div className='flex flex-col'>
              <div className='px-1 flex-1 flex items-center justify-between'>
                <div
                  className={cls(
                    'text-text text-body-md font-bold tracking-15 leading-4',
                    !creationMode.available ? 'text-text-subdued' : '',
                    needUpgrade ? 'text-text-disabled' : '',
                  )}
                >
                  {creationMode.name}
                </div>
                {creationMode.available ? null : (
                  <div className={cls('text-body-sm text-text-subdued ml-auto tracking-15')}>Coming soon</div>
                )}
              </div>
              {creationMode.membersOnly ? (
                <div className='flex items-center gap-1 text-body-sm px-1'>
                  <Link
                    href='/membership'
                    className='leading-5 tracking-15 font-bold text-text-interactive'
                    onClick={stopPropagation as any}
                  >
                    Members
                  </Link>
                  <span className=''>only</span>
                </div>
              ) : null}
              {creationMode.newFeature ? (
                <div className='absolute inset-y-0 left-4 flex items-center'>
                  <div className='flex items-center gap-1 text-body-xs md:text-body-sm leading-4 text-text-interactive font-medium whitespace-nowrap'>
                    <span className=''>{creationMode.newFeature}</span>
                  </div>
                </div>
              ) : null}
            </div>
            <div className={cls('size-10 rounded-full shrink-0 p-2 text-icon-on-color self-end', iconClassName)}>
              <Icon className={cls(!creationMode.available || needUpgrade ? 'opacity-50' : '')} />
            </div>
          </div>
          {creationMode.isNew ? <BadgeNew className='absolute top-1 md:top-2 right-1 md:right-2' /> : null}
          {creationMode.isUpdated ? <BadgeUpdate className='absolute top-1 md:top-2 right-1 md:right-2' /> : null}
        </div>
      )
    },
    [isFreePlan, mode, setActiveTemplateId, setCreationInput, setSubscriptionDialogContent],
  )

  return (
    <div className='size-full overflow-y-auto no-scrollbar' data-testid='explore-page'>
      <GlobalContainer className='static'>
        <div className='flex justify-between items-center gap-4 w-full'>
          <div className='flex items-center gap-4 md:gap-6'>
            <div className='text-heading-4xl text-text font-bold hidden md:flex' aria-label='Home'>
              Home
            </div>
            <div className='flex md:hidden justify-start text-text' aria-label='logo'>
              <Link href='/' className={cls('relative size-10 p-1', NO_OUTLINE_STYLE)} aria-label='signin logo'>
                {/* <Logo width={176} height={72} /> */}
                <LogoSymbol className='size-8' />
              </Link>
            </div>
          </div>
          <div className='flex gap-4 md:gap-8 items-center'>
            <SocialLinks />
            <CreditUpgradeButton showMembership className='md:h-10 px-2' source='explore' />
          </div>
        </div>
      </GlobalContainer>
      <div className='flex flex-col p-4 md:px-16 md:py-20 md:pt-2 gap-8'>
        <div className='flex flex-col md:flex-row items-center gap-8 md:h-50' aria-label='first-row'>
          <HomeContainer
            title='Apps'
            className={cls(
              'mb-4 md:mb-8 w-full md:w-0 md:flex-1 shrink relative',
              switchesLoading && !switches ? 'hidden' : '',
              'mb-0 md:mb-0',
            )}
            aria-label='creation-modes'
            data-testid='creation-modes'
          >
            <Scrollable className='w-full flex gap-3'>
              {creationModeOptions.filter((e) => !e.hidden).map(renderCreationModeItem)}
            </Scrollable>
          </HomeContainer>
          {showModel2Event && (
            <EventCard
              className='relative rounded-xl overflow-hidden cursor-pointer w-full md:w-1/4 min-w-60 h-full min-h-40 md:min-h-50 xl:min-h-full z-0 border border-border'
              data={model2Event}
              variant='primary'
            />
          )}
        </div>
        <div
          className='flex flex-col md:grid md:grid-cols-3 justify-between gap-8 overflow-hidden'
          aria-label='second-row'
        >
          <HomeContainer
            title='Templates'
            className={cls('flex-1 grow md:h-full md:col-span-2', browser?.name === 'safari' && 'max-h-max')}
            moreLink='/templates'
          >
            <TemplatesExploreCompact className='' />
          </HomeContainer>
          <HomeContainer
            title='Events'
            className='w-full h-full self-end md:h-full md:col-span-1 xl:min-h-[454px]'
            // moreLink='/events'
            moreLink=''
          >
            <HomeEvents className='' />
          </HomeContainer>
        </div>
        <div className='size-full mx-auto flex-1 flex flex-col mb-20' data-testid='creations'>
          <div className='w-full flex items-center justify-between'>
            <div className='text-heading-2xl font-bold pb-4'>Creations</div>
            <Link
              className='flex text-text no-underline hover:no-underline hover:opacity-80'
              aria-label='Creations'
              href='/creations'
            >
              <span className='text-body-md tracking-15'>Start Creating</span>
              <IconChevronRight className='size-5 text-icon' />
            </Link>
          </div>
          <Gallery
            waterfall
            showSpotlights={showSpotlightsInsideCreations}
            source='explore'
            items={galleryItems}
            loading={creationsLoading}
            filters={galleryFilters}
            defaultFilter={galleryFilters[0]}
            loadMore={() => loadMore?.()}
            onFilter={handleFilterGallery}
            onClick={handleGotoDetail}
          />
          {!creationsLoading && !hasMore && galleryItems?.length > 0 && <TheEnd className='mt-10' />}
        </div>
        <footer
          className={cls(
            'z-[2] w-full transition-all fixed md:absolute bottom-16 md:bottom-0 left-1/2 translate-x-[-50%] rounded-t-[30px] md:rounded-t-[24px] pointer-events-none',
          )}
          style={creationInputTranslateStyle}
        >
          <div className='size-full py-0 md:py-5 rounded-t-[30px] md:rounded-t-[24px]'>
            <CreationInput
              className='w-full items-end'
              toolbarDirection='up'
              onGenerate={(creationId: string) => {
                track('click:creation:generate', { creation_id: creationId })
                router.push('/creations')
              }}
            />
          </div>
        </footer>
      </div>
    </div>
  )
}
