'use client'

import { useCallback, useMemo } from 'react'
import { cls, whisper } from '@/utils'
import { Template } from '@/types'
import { useRouter } from 'next/navigation'
import TemplateCard from '../card'
import { useCachedAllTemplatesWithoutAuthCheck } from '@/hooks/useTemplates'
import { useCachedTemplateCategories } from '@/hooks/useTemplateCategories'
import { uniq } from 'lodash-es'
import TemplateOfTheDayCard from '../template-of-the-day-card'
import useTemplateOfTheDay from '@/hooks/useTemplateOfTheDay'
import { useBreakpoint } from '@/hooks/useBreakPoint'
import dayjs from 'dayjs'

export interface TemplatesExploreProps {
  className?: string
}

export default function TemplatesExploreCompact({ className }: TemplatesExploreProps) {
  const { data: templateCategories } = useCachedTemplateCategories()
  // const { data: allTemplatesRes } = useCachedAllTemplates()
  const { data: allTemplatesRes } = useCachedAllTemplatesWithoutAuthCheck()

  // const { isBelowMd } = useBreakpoint('md')
  const { isBelowXs } = useBreakpoint('xs')
  const verticalTemplatesCount = isBelowXs ? 2 : 3
  const horizontalTemplatesCount = isBelowXs ? 1 : 2

  const hotCategory = useMemo(() => {
    return templateCategories?.find((category) => category.category === 'hot')
  }, [templateCategories])

  const hotTags = useMemo(() => {
    return uniq((hotCategory?.tags ?? []).concat('hot'))
  }, [hotCategory])

  const allTemplates: Template[] = useMemo(() => {
    return allTemplatesRes?.records ?? []
  }, [allTemplatesRes])

  const hotTemplates: Template[] = useMemo(() => {
    const filtered = allTemplates.filter((template) => hotTags.some((tag) => template.tags.includes(tag)))
    const sorted = filtered.sort((a, b) => {
      if (a.rank !== b.rank) {
        return a.rank > b.rank ? -1 : 1
      }
      return dayjs(a.update_time).isBefore(dayjs(b.update_time)) ? 1 : -1
    })
    return sorted
  }, [allTemplates, hotTags])

  const verticalTemplates = useMemo(() => {
    return hotTemplates
      .filter((template) => template.cover_image_spec?.height > template.cover_image_spec?.width)
      .splice(0, verticalTemplatesCount)
  }, [hotTemplates, verticalTemplatesCount])

  const horizontalTemplates = useMemo(() => {
    return hotTemplates
      .filter((template) => template.cover_image_spec?.height < template.cover_image_spec?.width)
      .splice(0, horizontalTemplatesCount)
  }, [hotTemplates, horizontalTemplatesCount])

  const router = useRouter()

  const openTemplateDetail = useCallback(
    (templateId: string) => {
      router.push(`/template/${templateId}`)
    },
    [router],
  )

  const { data: templateOfTheDay } = useTemplateOfTheDay()
  if (1 > 2) {
    whisper('templateOfTheDay is: ', templateOfTheDay)
  }

  return (
    <div
      className={cls(
        'w-full z-0 gap-4 grid grid-cols-2 xs:grid-cols-6 xl:grid-cols-9 h-0 md:h-auto flex-1 md:grow-0',
        className,
      )}
    >
      <TemplateOfTheDayCard className='col-span-2 xs:col-span-6 xl:col-span-3 row-span-1 xs:row-span-1 xl:row-span-2 h-[140px] min-h-[140px] xs:max-h-none xl:min-h-[140px] xl:h-auto rounded-lg' />
      {horizontalTemplates.map((template) => (
        <TemplateCard
          key={template.template_id}
          className='col-span-2 xs:col-span-3 xl:col-span-3 row-span-1 rounded-lg'
          variant='compact'
          data={template}
        />
      ))}
      {verticalTemplates.map((template) => (
        <TemplateCard
          key={template.template_id}
          className='col-span-1 xs:col-span-2 xl:col-span-2 row-span-1 rounded-lg'
          variant='compact'
          data={template}
        />
      ))}
    </div>
  )
}
